import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function UploadResponse({ image, onRetake, tag, challengeID}) {
  // State variables for caption, and dialog state
  const [caption, setCaption] = useState(""); // State for caption input
  const [openDialog, setOpenDialog] = useState(false); // State for controlling dialog visibility
  const [uploadClicked, setUploadClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Function to send the challenge data to the backend
  const uploadResponse = async () => {
    try {
      // Upload button not useable so user can not spam uploads 
      setUploadClicked(true);
      setLoading(true)
      // Send response data to the backend API
       const response = await axios.post("/api/uploadResponse", {
         photoData: image.replace(/^data:image\/(png|jpeg);base64,/, ""), // Extract image data from base64 string
         caption: caption, // Include the caption
         challengeTag: tag, // Include the tag
         challengeID: challengeID,
       });
       console.log("Response from backend:", response.data);
      setLoading(false)

      // Open the dialog to confirm successful upload
      handleOpenDialog();
    } catch (error) {
      console.error("Error sending challenge to backend:", error);
    }
  };


  // Function to open the dialog
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  // Function to close the dialog and execute the onRetake callback
  const handleCloseDialog = () => {
    setOpenDialog(false);
  
    // Navigates to camera page 
    navigate("/camera");
  };

// JSX structure for the component
return (
  <div className="uploadResponse" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', overflowY: 'auto' }}>
    {/* Display captured photo */}
      <img data-testid="response-image" src={image} alt={""} style={{maxWidth: '100%', maxHeight: '80%'}}/> 
    {/* Drop down menu for people to choose the object they want people to match with */}
      <Typography style={{padding: '10px', fontWeight: 'bold'}}>
        It's a match!
      </Typography>
        <Typography style={{padding: '10px', fontWeight: 'bold'}}>
          Caption your photo!
        </Typography>
        {/* Text field for entering the caption */}
          <TextField
            variant="filled"
            label={"Enter caption here..."}
            value={caption}
            onChange={(e) => setCaption(e.target.value)}
          />
    {/* Buttons horizontal layout */}
    <div className="buttons" style={{display: 'flex', padding: '10px'}}> 
      {/* Button to retake */}
        <Button
          variant='contained'
          style={{ backgroundColor: '#72e478', color: '#000000', marginRight: '10px'}}
          onClick={onRetake}>
            Retake
        </Button>
      {/* Button to upload response*/}
        <Button 
          variant='contained'
          style={{ backgroundColor: '#72e478', color: '#000000', marginLeft: '10px'}} 
          onClick={uploadResponse}
          disabled={uploadClicked}>
            Upload response!
        </Button>          
    {/* Dialog for confirming successful upload */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle>Confirmation</DialogTitle>
              <DialogContent>
                <p>Uploaded successfully!</p>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary" data-testid="successfulResponse-button">
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
    </div>
  </div>
  );

}
