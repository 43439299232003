import React, { useState, useEffect } from "react";
import UploadResponse from "./UploadResponse";
import CaptureResponsePhoto from "./CaptureResponsePhoto";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

export default function ResponsePage({ tag, challengeID, setResponse }) {
  // State to store the captured image
  const [image, setImage] = useState();
  // State to store the match result
  const [isMatch, setIsMatch] = useState(false);
  // State to store whether the match result is received from the backend
  const [receivedMatchResults, setReceivedMatchResults] = useState(false);
  // State to store whether the back button is clicked
  const [backClicked, setBackClicked] = useState(false);

  // State to control the dialog visibility
  const [openDialog, setOpenDialog] = useState(false);
  // State to control when other component load
  const [imageTaken, setImageTaken] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (backClicked) {
      setResponse(false);
    }
  }, [backClicked]);

  // Function to handle retaking a photo
  const handleRetake = () => {
    // Reset the image and objectsArray states to null
    setImage(null);

    setIsMatch(false);
    setImageTaken(false);
    // Close the dialog
    setOpenDialog(false);
  };

  // Function to handle going back
  const handleGoBack = () => {
    // Navigate back to the previous page
    navigate("/camera");
    // Close the dialog
    setOpenDialog(false);
  };

  useEffect(() => {
    if (!isMatch) {
      setOpenDialog(true);
    }
  }, [isMatch, image]);

  return (
    <>
      {/* Render CaptureResponsePhoto component if image is not taken */}
      {!imageTaken && (
        <div>
          <Button 
            style={{ backgroundColor: '#000000', color: '#72e478'}} 
            onClick={() => setBackClicked(true)}>
              Back
          </Button>
          {/* Pass setImage and setObjectsArray functions as props */}
            <CaptureResponsePhoto
              onCaptureImage={setImage}
              onGetResult={setIsMatch}
              tag={tag}
              onSetImageTaken={setImageTaken}
            /> 
        </div>
      )}
      {/* Render UploadResponse component if the response can match the challenge */}
      {image && isMatch && imageTaken && (
        <UploadResponse
          // Pass handleRetake, image, and objectsArray as props
          onRetake={handleRetake}
          image={image}
          tag={tag}
          challengeID={challengeID}
        />
      )}
      {/* If the response can't match the challenge, render MatchFail component */}
      {image && !isMatch && imageTaken && (
        <>
          <Dialog open={openDialog}>
            <DialogTitle>Match Failed</DialogTitle>
            <DialogContent>
              <p>Sorry, the response did not match the challenge.</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRetake}>Retake Response</Button>
              <Button onClick={handleGoBack}>Go Back</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
