import React from 'react';
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PrivacyNotice = () => {
    
    const navigate = useNavigate();

    // Handles navigation to the next page after agreeing to the privacy notice
    const onAgree = () => {
        // Navigate to the 'login' page
        navigate('/')
    };

    return (
        <div className="privacy-notice" style={{marginLeft: "10px", marginBottom: "10px"}}>
            <h1>Privacy Notice</h1>
            <p>
                Please read this privacy notice carefully. It explains how we collect, use and safeguard your 
                information when you use SnapMatch. By using SnapMatch, you agree to the terms of this privacy notice.
            </p>
            <h3>
                Information we collect
            </h3>
            <p>
                We may collect personal information that you voluntarily provide to us when you use SnapMatch. 
                This information may include your username and password, and google email address if provided when you register an account.
            </p>
            <h3>
                How we use your provided information
            </h3>
            <p>
                We will use the information we collect to provide access to the app, through the creation of a user account.
                All uploaded images are viewable by anyone who has an account on the app.
            </p>
            <h3>
                Cookies for Session Management
            </h3>
            <p>
                SnapMatch uses cookies to allow users to stay logged in and maintain their session while using the 
                app. These cookies are essential to allow for proper functioning of the app and are set when you log 
                in to your account. By using the app, you consent to the usage of session cookies as described.
            </p>
            <h3>
                Data security
            </h3>
            <p>
                We take reasonable measures to protect the information we collect from unauthorized access.
            </p>
            <h3>
                Changes to this privacy notice
            </h3>
            <p>
                We reserve the right to update or change our privacy notice at any time.
            </p>
            <Button onClick={onAgree} style={{backgroundColor: '#72e478', color: '#000000'}}>
                Agree 
            </Button>
        </div>
    );

};

export default PrivacyNotice; 