import React, { useState } from "react";
import '../../App.css';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import axios from "axios";

export default function UploadChallenge({ image, onRetake, objectsArray }) {
  // State variables for selected option, custom option, caption, and dialog state
  const [option, setOption] = useState(objectsArray[0]); // Initially set the option to the first item in objectsArray
  const [customOption, setCustomOption] = useState(""); // State for custom option input
  const [caption, setCaption] = useState(""); // State for caption input
  const [openDialog, setOpenDialog] = useState(false); // State for controlling dialog visibility
  const [loading, setLoading] = useState(false);

  // Function to send the challenge data to the backend
  const uploadChallenge = async () => {
    try {
      // Send challenge data to the backend API
      setLoading(true)
      const response = await axios.post("/api/uploadChallenge", {
        photoData: image.replace(/^data:image\/(png|jpeg);base64,/, ""), // Extract image data from base64 string
        caption: caption, // Include the caption
        tag: option, // Include the tag
      });
      setLoading(false)
      // Open the dialog to confirm successful upload
      handleOpenDialog();
    } catch (error) {
      console.error("Error sending challenge to backend:", error);
    }
  };

  // Event handler for selecting an option from the dropdown
  const handleOptionChange = (e) => {
    const selectedOption = e.target.value;
    // Update the selected option state
    setOption(selectedOption);
  };


  // Function to open the dialog
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  // Function to close the dialog and execute the onRetake callback
  const handleCloseDialog = () => {
    setOpenDialog(false);
    // Execute the onRetake callback
    onRetake();
  };

  // JSX structure for the component
  return (
    <div className="uploadChallenge" style={{ display: 'flex', flexDirection: 'column', marginBottom: 'calc(8vh + 2px)', marginTop: 'calc(8vh + 2px)', alignItems: 'center', textAlign: 'center', overflowY: 'auto' }}>
      {/* Display captured photo */}
        <img src={image} alt={""} style={{maxWidth: '100%', maxHeight: '80%'}}/> 
      {/* Drop down menu for people to choose the object they want people to match with */}
        <Typography style={{padding: '10px', fontWeight: 'bold'}}>
          Select the object you'd like people to match!
        </Typography>
          {/* Check if objectsArray is empty */}
            {objectsArray && objectsArray.length > 0 ? (
              <Box>
                <Select
                  value={option}
                  onChange={handleOptionChange}
                  size={"small"}
                  fullWidth
                >
                  {/* Render dropdown options */}
                    {objectsArray.map((obj, index) => (
                      <MenuItem key={index} value={obj}>
                        {obj}
                      </MenuItem>
                    ))}
                  {/* Option for specifying a custom object */}
                    {/*<MenuItem value={'Other'}>Other</MenuItem>*/}
                </Select>
              </Box>
            ) : (
              // Renders "could not identify objects" if it is empty
                <Typography style={{padding: '5px'}}>
                  Couldn't identify objects
                </Typography>
            )}
          <Typography style={{padding: '10px', fontWeight: 'bold'}}>
            Caption your photo!
          </Typography>
          {/* Text field for entering the caption */}
            <TextField
              variant="filled"
              label={"Enter caption here..."}
              value={caption}
              onChange={(e) => setCaption(e.target.value)}
            />
      {/* Buttons horizontal layout */}
      <div className="buttons" style={{display: 'flex', padding: '10px'}}> 
        {/* Button to retake */}
          <Button
            variant='contained'
            style={{ backgroundColor: '#72e478', color: '#000000', marginRight: '10px'}}
            onClick={onRetake}>
              Retake
          </Button>
        {/* Button to upload challenge*/}
          <Button 
            variant='contained'
            style={{ backgroundColor: '#72e478', color: '#000000', marginLeft: '10px'}} 
            onClick={uploadChallenge}>
              Upload challenge!
          </Button>
        {/* Render overlay with loading indicator */}
          {loading && (
              <div className="overlay">
                <CircularProgress color="primary"/>
              </div>
            )}            
      </div>
      <Box>
        {/* Dialog for confirming successful upload */}
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            <p>You have uploaded successfully!</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
}
