import {useEffect, useState} from "react";
import axios from 'axios';

/**
 * Custom hook to handle fetching and managing challenges for components other than MyChallenges.
 * @returns {Object} An object containing the challenges array and the deleteChallenge function.
 */
export const useOtherChallenges = () => {
    // State to store challenges array
    const [challenges, setChallenges] = useState([]);

    // Fetch challenges on component mount
    useEffect(() => {
    const fetchChallenges = async () => {
        try {
            const response = await axios.get('/api/getAllChallenges');
            const challenges = response.data;
            setChallenges(challenges);
        } catch (err) {
            console.error('Error fetching challenges:', err);
        }
    };

    fetchChallenges();
    }, []);

    // Return challenges array function
    return {
        challenges
    }
}
