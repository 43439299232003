import GoogleButton from "react-google-button";

const GoogleLogin = ({ handler }) => {
  return (
    <GoogleButton onClick={handler} type="light"></GoogleButton>
    // <a href="http://127.0.0.1:5000/api/google/login">Log in with Google</a>
  );
};

export default GoogleLogin;
