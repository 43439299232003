import {Button, Card, CardContent, Grid, CircularProgress} from "@mui/material";
import React, {useState } from "react";

/**
 * Component to display a list of challenges.
 * @param {Object} props - Component properties.
 * @param {Array} props.challenges - List of challenges to display.
 * @param {Function} props.onClickMyChallenge - Callback function for the "Look" button.
 * @param {Object} props.challenge - Currently selected challenge.
 * @returns {JSX.Element} Challenges list component.
 */
export default function ChallengesList({challenges=[], onClickMyChallenge}) {
    const [imageLoaded, setImageLoaded] = useState(false);

  // Function to handle image load event
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  // If no challenges are provided, display a message
    if (challenges.length === 0) {
      return (
        <div style={{ height: '100%', padding: '8px' }}>
          <h3>No challenges found</h3>
        </div>
      );
  }

return (
  <Grid container spacing={2}  data-testid="challenges-list">
    {/* Mapping through challenges and rendering each one */}
    {challenges.map(item => (
      <Grid item key={item.ChallengeID} xs={6} sm={6} md={4} lg={3}>
        {/* Challenge Card */}
        <Card style={{ width: '100%' }} data-testid="challenge-item">
          {/* Card Content */}
          <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
            {/* Challenge Title */}
            <div data-testid="challenge-title">
              <h4 style={{ margin: 0 }}>{item.Tags}</h4>
            </div>
            {/* Image Button or Loading Spinner */}
            {imageLoaded ? (
              <Button data-testid="challenge-image"
                onClick={() => {onClickMyChallenge && onClickMyChallenge(item)}}
                variant={'contained'}
                style={{
                  backgroundImage: `url(${item.ImgPath}?cache=${Date.now()})`, // Set the image as background
                  backgroundSize: 'cover', // Adjust background size as needed
                  backgroundPosition: 'center', // Center image in button
                  height: '30vh', // Adjust height as needed
                  width: '80%', // Adjust width as needed
                  backgroundColor: '#ffffff', // Set default background color to white
                }}
              >
              </Button>
            ) : (
              <div>
                <CircularProgress />
                <img
                  src={`${item.ImgPath}?cache=${Date.now()}`}
                  alt=""
                  onLoad={handleImageLoad}
                  style={{
                    display: 'none',
                  }}
                />
              </div>
            )}
            {/* Challenge Description */}
            <p style={{ margin: 0 }} data-testid="challenge-username">
              <small>
                @{item.UserName}
              </small>
            </p>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
);
}
