import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import ResponsePage from "../../response/ResponsePage";
import ViewResponses from '../../response/ViewResponses';

/**
 * Component to display details of a challenge, including image, tags, caption, and action buttons.
 * @param {Object} props - Component properties.
 * @param {Object} props.challenge - The challenge object to display details.
 * @param {boolean} props.other - Indicates if the challenge is from "Other Challenges."
 * @param {boolean} props.onlyPreview - Indicates if the component is for preview only.
 * @returns {JSX.Element} Challenge detail component.
 */
export default function ChallengeDetail({
  challenge,
  other,
  onlyPreview,
  ifNotBack,
}) {
  // Hook to navigate to different routes
  // const navigate = useNavigate();
  const [response, setResponse] = useState(false);
  const [viewResponse, setViewResponse] = useState(false);

  // If no challenge is provided, return an empty fragment
  if (!challenge) {
    return <></>;
  }

  // Append the timestamp as a query parameter to invalidate the cache
  const imgUrlWithCacheInvalidation = `${challenge.ImgPath}?cache=${Date.now()}`;

  return (
    <div data-testid="challenge-detail" style={{}}>
      {/* if the user hasn't clicked response button, show the challenge detail */}
      {!response && !viewResponse && (
        <div style={{display: 'flex', flexDirection: 'column',  alignItems: 'center', overflowY: 'auto'}}>
          {/* Back button to exit look mode */}
          <Button
            style={{backgroundColor: 'black', color: '#72e478', marginTop: '5px', marginBottom: '5px'}}
            onClick={() => {
              ifNotBack(false);
            }}
            variant={"contained"}
          >
            Back
          </Button>
          <div style={{maxWidth: '500px'}}>
            {/* Displaying the image */}
              <img
              src={imgUrlWithCacheInvalidation}
              alt=""
              style={{maxWidth: '100%', maxHeight: '100%'}}
              data-testid="challenge-image"
            />
          </div>

          <div style={{padding: '10px', textAlign: 'center'}}>
            {/* Displaying the Caption */}
              <Typography data-testid="challenge-caption">
                {challenge.Caption}
              </Typography>
            {/* Displaying the Tags */}
              <Typography data-testid="challenge-tag">
                Object to match: {challenge.Tags}
              </Typography>            
          </div>
          <div style={{display: 'flex', marginBottom: '10px'}}>
            {/* Action buttons */}
            {!onlyPreview && (
              <div>
                {/* Show response button (for "Other Challenges" only) */}
                 {other ? (
                  <>
                    <Button
                      style={{backgroundColor: 'black', color: '#72e478'}}
                      onClick={() => setResponse(true)}
                    >
                      Respond!
                    </Button>
                    <Button
                      style={{backgroundColor: 'black', color: '#72e478', marginLeft: '10px'}}
                      onClick={() => setViewResponse(true)}
                    >
                      View Responses
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </div>
            )}
            
          </div>   
        </div>
      )}
    {/* if the user has clicked the respond button, the respond pages are rendered*/}
      {response && (
        <ResponsePage
          tag={challenge.Tags}
          challengeID={challenge.ChallengeID}
          setResponse={setResponse}
        ></ResponsePage>
      )}
    {/* if the user has clicked the view responses button, the responses are rendered */}
      {viewResponse &&(
      <ViewResponses 
        challengeID={challenge.ChallengeID}
        onBackButtonClick={() => setViewResponse(false)}
      ></ViewResponses>
      )
}
    </div>
  );
}
