import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // import useNavigate
import axios from "axios";

function GoogleReturnMenu() {
  const location = useLocation(); // Hook to access the location object
  const navigate = useNavigate(); // Hook to get the navigate function

  // Extracting 'userid' query parameter from URL
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("userid");

  // useEffect to handle user session once the component mounts or userId changes
  useEffect(() => {
    const saveSession = async () => {
      if (userId) { // Checks if userId is not null
        try {
          const userData = { user_id: userId };

          // Post request to backend to save the user session
          const response = await axios.post(
            "/api/google/login/savesession",
            userData
          );

          console.log("Response from backend:", response.data);

          // If successfully login, store userId and jump to the camera page
          if (response.data.message === "User logged in successfully") {
            console.log("Login success:", response.data.message);
            window.localStorage.setItem("userInfo", JSON.stringify({ userId }));
            // Navigate to 'camera' page if login is successful
            navigate("/camera");
          } else {
             // Navigate to home if login is unsuccessful
            console.log("Login failed:", response.data.message);
            navigate("/");
          }
        } catch (error) {
          // Log and handle errors if the request fails
          console.error(
            "Error during login:",
            error.response ? error.response.data.message : error.message
          );
          navigate("/");
        }
      } else {
        // Log and redirect if no userId is found in the query parameters
        console.log("No user ID found");
        navigate("/");
      }
    };

    // Execute the saveSession function
    saveSession();
  }, [userId, navigate]); // Add navigate as the dependency of useEffect

  return (
    <div>
      <h1>Google Login Return Menu</h1>
      {userId && <p>user ID: {userId}</p>} 
    </div>
  );
}

export default GoogleReturnMenu;
