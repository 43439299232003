import { useState } from "react";
import CapturePhoto from "./CapturePhoto";
import UploadChallenge from "./UploadChallenge";

export default function Studio() {
  // State to store the captured image
  const [image, setImage] = useState();
  // State to store the array of objects identified in the captured image
  const [objectsArray, setObjectsArray] = useState();

  // Function to handle retaking a photo
  const handleRetake = () => {
    // Reset the image and objectsArray states to null
    setImage(null);
    setObjectsArray(null);
  };

  return (
    <>
      {/* Render CapturePhoto component if image or objectsArray is not available */}
      {(!image || !objectsArray) && (
        <div className={""}>
          {/* Pass setImage and setObjectsArray functions as props */}
          <CapturePhoto
            onCaptureImage={setImage}
            onGetObjectArray={setObjectsArray}
          />
        </div>
      )}
      {/* Render UploadChallenge component if both image and objectsArray are available */}
      {image && objectsArray && (
        <UploadChallenge
          // Pass handleRetake, image, and objectsArray as props
          onRetake={handleRetake}
          image={image}
          objectsArray={objectsArray}
        />
      )}
    </>
  );
}
