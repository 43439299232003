import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {CircularProgress, Grid, Card, CardContent, Button} from '@mui/material';


/**
 * Component to display responses given by other users.
 * @returns {JSX.Element} Component for viewing responses.
 */
const ViewResponses = ({challengeID, onBackButtonClick}) => {
  // State to store responses
  const [responses, setResponses] = useState([]);
  // State to control loading
  const [loading, setLoading] = useState(false);


  // Function to handle image load event
  const handleImageLoad = () => {
      setLoading(true);
  };


  useEffect(() => {
    // Function to fetch responses
    const fetchResponses = async () => {
    try {
      const response = await axios.post('/api/getResponsesByChallengeID', {challengeID: challengeID});
      const responses = response.data;
      setResponses(responses);
    } catch (error) {
      console.error('Error fetching responses:', error);
    }
  };
    fetchResponses();
  }, [challengeID]);

  // If no responses are provided, display a message
  if (responses.length === 0) {
    return (
      <div className={'d-flex justify-content-center align-items-center mt-5'} style={{
        height: '100%'
      }}>
        <h3>No responses found</h3>
        <Button
          style={{backgroundColor: 'black', color: '#72e478'}}
          onClick={onBackButtonClick} >Back
        </Button>
      </div>
    );
  }
  return (
    <div>
        <Button
          style={{backgroundColor: 'black', color: '#72e478', marginTop: '5px', marginBottom: '5px', marginLeft: '5px'}}
          onClick={onBackButtonClick} >Back
        </Button>
      <Grid container spacing={2}>
      {responses.map(item => (
        <Grid item key={item.responseID} xs={6} sm={6} md={4} lg={3}>
          {/* Challenge Card */}
          <Card style={{ width: '100%' }}>
            {/* Card Content */}
            <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
              {/* Challenge Title */}
              <div data-testid="challenge-title">
                <h4 style={{ margin: 0 }}>{item.CommentText}</h4>
              </div>
              {/* Image Button or Loading Spinner */}
              {!loading ? (
              <div>
                <CircularProgress />
                <img
                    src={`${item.ImagePath}?cache=${Date.now()}`}
                    alt=""
                    onLoad={handleImageLoad}
                    style={{
                      display: 'none',
                    }}
                  />
                </div>
              ) : (
              <Button data-testid="challenge-image"
                variant={'contained'}
                style={{
                  backgroundImage: `url(${item.ImagePath}?cache=${Date.now()})`, // Set the image as background
                  backgroundSize: 'cover', // Adjust background size as needed
                  width: '80%', // Adjust width as needed
                  height: '30vh', // Adjust height as needed
                  backgroundPosition: 'center', // Center image in button
                  backgroundColor: '#ffffff', // Set default background color to white
                }}
              >
              </Button>
              )}
              {/* Challenge Description */}
              <p style={{ margin: 0 }} data-testid="challenge-username">
                <small>
                  @{item.UserName}
                </small>
              </p>
            </CardContent>
          </Card>
        </Grid>       
        ))}
      </Grid>
    </div>
    
      );}

export default ViewResponses;
