import React, { useState } from "react";
import "./LoginRegister.css";
import user_icon from "../assets/person.png";
import password_icon from "../assets/password.png";
import GoogleLogin from "../google-login/GoogleLogin";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Component for user login and registration
  const LoginRegister = () => {
    const [action, setAction] = useState("Login");
    const [openDialogL, setOpenDialogL] = useState(false); // Dialog state for login case
    const [openDialogR, setOpenDialogR] = useState(false); // Dialog state for register case
    const [loginSuccess, setLoginSuccess] = useState(false); // State to track login success
    const [registerSuccess, setRegisterSuccess] = useState(false); // State to track registration success
    const [loginJump, setLoginJump] = useState(false);       // State to trigger navigation after successful login
    const [username, setUsername] = useState("");            // State to store username input
    const [password, setPassword] = useState("");            // State to store password input
    const [passwordConfirmation, setPasswordConfirmation] = useState("") //State to store password confirmation
    const [errorMessage, setErrorMesage] = useState("")      // Add state for error message
    const navigate = useNavigate();

  // Handles the submission of login or registration form
  const handleSubmit = async () => {
    console.log(
      "Logging in with username:",
      username,
      "and password:",
      password
    );

    if (action === "Login") {
      //Perform login logic
      try {
        //if the input is empty
        if(password === "" || username === ""){
          handleLoginOpenFailDialog("Your input can't be empty.")
        }

        //Send login details to the backend
        const response = await axios.post("/api/login", { username, password });
        console.log("Response from backend:", response.data);

        //Check the matching message from backend
        if (response.data.message === "User logged in successfully") {
          // Allow navigation to the next page
          setLoginJump(true);
          handleLoginOpenSuccDialog();
        } else {
          handleLoginOpenFailDialog("Wrong username or password");
        }

        console.log(
          "Logging in with username:",
          username,
          "and password:",
          password
        );
        // Here you can store the login details as needed
      } catch (error) {
        console.error('Error during login:', error);
        handleLoginOpenFailDialog();
      }
    } else {
      //Perform registration logic
      try {
        //if the input is empty
        if(password === "" || username === ""){
          handleRegisterOpenFailDialog("Your input can't be empty.")
        }
        //Check if the password and paasword confirmation match
        if(password != passwordConfirmation){
          handleRegisterOpenFailDialog("Password couldn't match confirmation password.");

        }
        else{
        //Send registration details to the backend
        const response = await axios.post("/api/register", {
          username,
          password,
        });
        console.log("Response from backend:", response.data);

        //Check the matching message from backend
        if (response.data.message === "User registered successfully") {
          // Display success dialog and switch to login mode
          handleRegisterOpenSuccDialog();
          setAction("Login");
        } else {
          handleRegisterOpenFailDialog("Register error.");
        }
        console.log(
          "Registering with username:",
          username,
          "and password:",
          password
        );
        // Here you can store the registration details as needed
        }
        
      } catch (error) {
        console.error('Error during registration:', error);
        handleRegisterOpenFailDialog("Error during registration");
      }
    }
  };

  const handleGoogleLogin = async () => {
    let apiBaseUrl;
    // check where is the backend to go to the right api
    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
      apiBaseUrl = "http://localhost:5000";
    } else {
      apiBaseUrl = "https://snapmatch.loca.lt";
    }
    const loginUrl = `${apiBaseUrl}/api/google/login`;
    // redirect to the google login url in the backend
    window.location.href = loginUrl;
  };

  // Opens success dialog for login
  const handleLoginOpenSuccDialog = () => {
    setOpenDialogL(true);
    setLoginSuccess(true);
  };

  // Opens fail dialog for login
  const handleLoginOpenFailDialog = (errorMessage) => {
    setOpenDialogL(true);
    setLoginSuccess(false);

    //Set error message passed as parameter
    setErrorMesage(errorMessage)
  };

  // Closes login dialog
  const handleLoginCloseDialog = () => {
    setOpenDialogL(false);
    // Navigate to the next page if login was successful
    if (loginJump) {
      onRetake();
    }
  };

  // Handles navigation to the next page after successful login
  const onRetake = () => {
    // Store user info in local storage (this is a placeholder, replace it with actual implementation)
    window.localStorage.setItem(
      "userInfo",
      JSON.stringify({ username, password })
    );
    // Navigate to the 'camera' page
    navigate("/camera");
  };

  // Opens success dialog for registration
  const handleRegisterOpenSuccDialog = () => {
    setOpenDialogR(true);
    setRegisterSuccess(true);
  };

  // Opens fail dialog for registration
  const handleRegisterOpenFailDialog = (errorMessage) => {
    setOpenDialogR(true);
    setRegisterSuccess(false);

    //Set error message passed as parameter
    setErrorMesage(errorMessage)
  }

  // Closes registration dialog
  const handleRegisterCloseDialog = () => {
    setOpenDialogR(false);
  };

  // Switches to login mode
  const handleLogin = () => {
    setAction("Login");
    // Clean the input box
    setUsername("");
    setPassword("");
  };

  // Switches to register mode
  const handleRegister = () => {
    setAction("Register");
    // Clean the input box
    setUsername("");
    setPassword("");
  };

  // creates login page and register page with a username and password
  // also has a submit button to submit the details to backend
  return (
    <div className="container" style={{minHeight: "100vh"}}>
      <div className="header">
        <div className="text">SnapMatch</div>
      </div>
      <div className="submit-container">
        <div
          className={action === "Login" ? "submit gray" : "submit"}
          onClick={handleRegister}
        >
          Register
        </div>
        <div
          className={action === "Register" ? "submit gray" : "submit"}
          onClick={handleLogin}
        >
          Login
        </div>
      </div>

      <div className="header">
        <div className="text">{action}</div>
        <div className="underline"></div>
      </div>

      <div className="inputs">
        <div className="input">
          <img src={user_icon} alt="" />
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="input">
          <img src={password_icon} alt="" />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {action === "Register" &&
        (
          <div className='input'>
          <img src={password_icon} alt="" />
          <input
            type="confirmPassword"
            placeholder="Confirm Password"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
        </div>
        )}
        
        <div className="submit-container" style={{display: "flex", flexDirection: "column", alignItems: "center", padding: '10px'}}>
          {/*Privacy Notice info*/}
          <p>By clicking submit you are agreeing to our <a href="/privacy-notice">privacy notice</a>.</p>
          {/* Submit button for login/registration */}
          <div className="submit" onClick={handleSubmit}>
            Submit
          </div>
        </div>
      </div>

      {/* Google Login Button */}
      <div className="center">
        <GoogleLogin handler={handleGoogleLogin}></GoogleLogin>
      </div>

      {/* Login success/fail dialog */}
      <Dialog open={openDialogL} onClose={handleLoginOpenFailDialog}>
        <DialogTitle>
          {loginSuccess ? "Login Successful" : "Login Failed"}
        </DialogTitle>
        <DialogContent>
          <p>
            {loginSuccess
              ? 'You have successfully logged in.'
              : errorMessage}
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLoginCloseDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Registration success/fail dialog */}
      <Dialog open={openDialogR} onClose={handleRegisterOpenFailDialog}>
        <DialogTitle>
          {registerSuccess ? "Register Successful" : "Register Failed"}
        </DialogTitle>
        <DialogContent>
          <p>
            {registerSuccess
              ? 'You have successfully registered.'
              : errorMessage}
          </p>
          <DialogActions>
            <Button onClick={handleRegisterCloseDialog} color="primary">
              OK
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LoginRegister;
