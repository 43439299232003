import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import { Button, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import '../../App.css';

const CaptureResponsePhoto = ({ onCaptureImage, onGetResult, tag, onSetImageTaken }) => {
  // Refs for video and canvas elements
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  // State to track whether camera access is enabled
  const [cameraEnable, setCameraEnable] = useState(false);
  const [currentCamera, setCurrentCamera] = useState("user");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // Function to go back to the previous page
  // const goBack = () => {
  //   navigate("/other-challenges");
  // };

  // Function to get access to the camera
  const getCameraAccess = async () => {
    try {
      // Set camera access to true
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: currentCamera } });
      setCameraEnable(true);
    } catch (error) {
      // Alert user if there's an error accessing the camera
      alert(`Can't get access of your camera!`);
      console.error("Error accessing camera:", error);
    }
  };

  // Function to switch between the front and back camera
  const switchCamera = () => {
    setCurrentCamera(currentCamera === "user" ? "environment" : "user");
  };

  // Function to send information to backend to check if the response image can match the challenge
  const checkIfMatch = async (capturedImage, tag) => {
    try {
      const response = await axios.post("/api/checkIfMatch", {
        // This removes the data URL prefix making it easier to handle at the backend
        photoData: capturedImage.replace(/^data:image\/(png|jpeg);base64,/, ""),
        challengeTag: tag,
      });
      // Return the match result from the response
      return response.data.data;
    } catch (error) {
      console.error("Error sending photo to backend:", error);
      return null;
    }
  };

  // Function to capture image from video stream
  const captureImage = async () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (canvas && video) {
      // Capture image from video stream and draw on canvas
      const context = canvas.getContext('2d');
      // Save current canvas state
      context.save();

      // Flip canvas horizontally if front facing camera
      if (currentCamera === 'user') {
        context.translate(canvas.width, 0); // Flip context horizontally
        context.scale(-1, 1); // Horizontal flip
      }
      
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      // Restore context to original state
      context.restore();
      // Convert canvas image to base64 data URL
      const image = canvas.toDataURL("image/png");
      // Call callback function to handle captured image
      onCaptureImage(image);
      // Pause video playback
      video.pause();
      setLoading(true);
      // Receives verification on whether image is a match or not
      const matchResult = await checkIfMatch(image ,tag);
      setLoading(false);
      // Call callback function to handle the result from backend
      onSetImageTaken(true);
      onGetResult(matchResult);
      // Stop video stream
      video.srcObject.getTracks().forEach((track) => track.stop());
    }
  };

  // Effect hook to handle camera access
  useEffect(() => {
    getCameraAccess();
    // Function to show video stream on the video element
    const showVideo = async () => {
      // Get access to the user's camera
      const constraints = { video: { facingMode: currentCamera } };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      if (videoRef.current && stream) {
        // Set the video stream as the source for the video element
        videoRef.current.srcObject = stream;

        // Once the video stream is loaded, update the canvas size
        videoRef.current.onloadedmetadata = () => {
          // Set canvas dimensions to match the video element
          const videoWidth = videoRef.current.videoWidth;
          const videoHeight = videoRef.current.videoHeight;
          canvasRef.current.width = videoWidth;
          canvasRef.current.height = videoHeight;
        };

        // Flip the video horizontally if using the front-facing camera
        if (currentCamera === 'user') {
          videoRef.current.style.transform = 'scaleX(-1)';
        } else {
          videoRef.current.style.transform = 'none'; // unflip if using the back camera
        }
      }
    };
    // Call the function to show video stream
    showVideo();
  }, [cameraEnable, currentCamera]);

  // Render camera access UI if camera access is not enabled
  if (!cameraEnable) {
    return (
      <div>
        <Typography>
          Getting access to your camera...
        </Typography>
      </div>
    );
  }

// Render camera capture UI if camera access is enabled
  return (
    // If camera access is enabled, render camera view and capture functionality
      <div className="capturePhoto" style={{ display: 'flex', flexDirection: 'column', marginTop: 'calc(8vh + 2px)', alignItems: 'center', marginBottom: 'calc(8vh + 2px)' }}>
        {/* Display video stream */}
        <div style={{maxWidth: '500px'}}>
          <video 
            style={{maxWidth: '100%', maxHeight: '80%'}}
            ref={videoRef} autoPlay playsInline />
        </div>
        <div className="buttons" style={{display: 'flex'}}> 
        {/* Button to capture photo */}
          <Button
            variant='contained'
            style={{ backgroundColor: '#72e478', color: '#000000', marginRight: '10px'}}
            onClick={captureImage}>
              Take Photo
          </Button>
        {/* Render overlay with loading indicator */}
          {loading && (
            <div className="overlay">
              <CircularProgress color="primary"/>
            </div>
          )}
        {/* Button to switch camera*/}
          <Button 
            variant='contained'
            style={{ backgroundColor: '#72e478', color: '#000000', marginLeft: '10px'}} 
            onClick={switchCamera}>
              Switch Camera
          </Button>
        </div>
        {/* Hidden canvas element to draw captured image */}
        <canvas ref={canvasRef} style={{ display: 'none' }} />
      </div>
  );
};

export default CaptureResponsePhoto;
