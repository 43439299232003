import { Box, Grid, Button } from "@mui/material";
import ChallengesList from "./subcomponents/ChallengesList";
import MyChallengeDetail from "./subcomponents/MyChallengeDetail";
import { useState } from "react";
import { useMyChallenges } from "../hooks/useMyChallenges";
import ViewResponses from "../response/ViewResponses";

/**
 * Functional component representing the MyChallenges section.
 * Displays a list of challenges and allows users to view details.
 */
export default function MyChallenges() {
  // Custom hook to fetch challenges from the backend
  const { challenges } = useMyChallenges();

  // State variables to manage selected challenge and look mode
  const [challenge, setChallenge] = useState();
  const [isLook, setIsLook] = useState();

  const [viewResponse, setViewResponse] = useState(false);

  /**
   * Handles the click event when a challenge is selected to view details.
   * Sets the selected challenge and enters the look mode.
   * @param {object} target - The selected challenge object.
   */
  const handleLook = (target) => {
    setChallenge(target);
    setIsLook(true);
  };

  /**
   * Handles the click event when "View Response" button is clicked.
   * Sets the viewResponse state to true.
   */
  const handleViewResponse = () => {
    setViewResponse(true);
  };

  /**
   * Renders the MyChallenges component.
   */
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: "0",
        marginTop: "calc(8vh + 2px)",
        marginBottom: "calc(8vh + 2px)",
        overflowY: "auto",
      }}
    >
      {isLook && !viewResponse ? (
        // Display ChallengeDetail when in look mode
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <MyChallengeDetail challenge={challenge} />
          <div>
            {/* Back button to exit look mode */}
            <Button
              style={{ backgroundColor: "black", color: "#72e478" }}
              onClick={() => {
                setIsLook(false);
              }}
              variant={"contained"}
            >
              Back to my challenges
            </Button>
            <Button
              style={{
                backgroundColor: "black",
                color: "#72e478",
                marginLeft: "10px",
              }}
              onClick={handleViewResponse}
            >
              View Responses
            </Button>
          </div>
        </div>
      ) : (
        // Display ChallengesList when not in look mode or ViewResponses when viewResponse is true
        <Grid item xs={13}>
          {viewResponse ? (
            <ViewResponses
              challengeID={challenge ? challenge.ChallengeID : null}
              onBackButtonClick={() => setViewResponse(false)}
            />
          ) : (
            <ChallengesList
              challenge={challenge}
              onClickMyChallenge={handleLook}
              challenges={challenges}
            />
          )}
        </Grid>
      )}
    </div>
  );
}
