import { Box, Grid, useMediaQuery } from "@mui/material";
import ChallengesList from "./subcomponents/ChallengesList";
import ChallengeDetail from "./subcomponents/ChallengeDetail";
import { useState } from "react";
import { useOtherChallenges } from "../hooks/useOtherChallenges";


/**
 * Functional component representing the OtherChallenges section.
 * Displays a list of challenges and allows users to view details, delete.
 */
export default function OtherChallenges() {
  // Custom hook to fetch challenges from the backend
  const { challenges } = useOtherChallenges();

  // State var to manage selected challenge
  const [challenge, setChallenge] = useState();
  // State var to track if in 'look' mode
  const [isLook, setIsLook] = useState();

  /**
   * Handles the click event when a challenge is selected to view details.
   * Sets the selected challenge and enters the look mode.
   * @param {object} target - The selected challenge object.
   */
  const handleLook = (target) => {
    setChallenge(target);
    setIsLook(true);
  };

  /**
   * Renders the OtherChallenges component.
   */
  return (
    <div style={{display: 'flex', flexDirection: 'column', flexGrow: '0', marginTop: 'calc(8vh + 2px)', overflowY: 'auto', marginBottom: 'calc(8vh + 2px)'}}>
        {isLook ? (
          // Display ChallengeDetail when in look mode
          <div>
            <ChallengeDetail
              other={true}
              challenge={challenge}
              ifNotBack={setIsLook}
            />
          </div>
        ) : (
          // Display ChallengesList when not in look mode
          <ChallengesList
            challenge={challenge}
            onClickMyChallenge={handleLook}
            challenges={challenges}
          />
        )}
    </div>
  );
}
