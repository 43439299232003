import React, { } from 'react';
import LoginRegister from './components/auth/loginRegister'
// import './App.css';
import { Route } from 'react-router-dom';
import { Routes, } from "react-router-dom";
// Importing all the required components from their respective files
import MyChallenges from "./components/challenge/my-challenges";
import GoogleReturnMenu from './components/google-login/GoogleReturnMenu';
import Studio from "./components/studio/Studio";
import OtherChallenges from "./components/challenge/other-challenges";
import Layout from './components/common/layout';
import PrivacyNotice from './components/auth/PrivacyNotice'
import axios from 'axios';
import Leaderboard from './components/common/Leaderboard';
axios.defaults.withCredentials = true;

function App() {
    // // JSX of App.js, setting up the router and defining routes for the application
    return (
        <Routes>
             {/* Route for the root path that renders the login/register component */}
            <Route path={''} index element={<LoginRegister />} />

            {/* Route for the privacy notice page */}
            <Route path={'privacy-notice'} element={<PrivacyNotice />} />

            {/* Route for the "My Challenges" section, wrapped in the common layout */}
            <Route path={'my-challenges'} element={<Layout><MyChallenges /></Layout>} />

            {/* Route for the camera/studio section, also wrapped in the common layout */}
            <Route path={'camera'} element={<Layout><Studio /></Layout>} />

             {/* Route for viewing other challenges, within the layout */}
            <Route path={'other-challenges'} element={<Layout><OtherChallenges /></Layout>} />

            {/* Route for the leaderboard, wrapped in the layout */}
            <Route path={'leaderboard'} element={<Layout><Leaderboard /></Layout>} />
            
            {/* Route for handling Google login return */}
            <Route path={'google_return_menu'} element={<GoogleReturnMenu />} />
        </Routes>
    );
}

export default App;
