import React, { useCallback } from "react";
import { Box, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import "./layout.css";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GroupsIcon from "@mui/icons-material/Groups";
import axios from "axios";
import LeaderboardIcon from "@mui/icons-material/EmojiEvents";

/**
 * Layout component for the application.
 * @param {Object} props - Component properties.
 * @param {JSX.Element} props.children - Child components to be rendered within the layout.
 * @returns {JSX.Element} Layout component.
 */
function Layout({ children }) {
  // Custom hook from react-router-dom for navigation
  const navigate = useNavigate();
  // Custom hook from react-router-dom to get the current location
  const location = useLocation();
  // Extracting the pathname from the location object
  const currentPath = location.pathname;

  /**
   * Function to check if a given pathname is active.
   * @param {string} pathname - The pathname to check against.
   * @returns {boolean} True if the pathname is active, otherwise false.
   */
  const isActive = useCallback(
    (pathname) => {
      return currentPath === pathname;
    },
    [currentPath]
  );

  const handleLogout = async (provider) => {
    try {
      let logoutUrl;
      if (provider === "google") {
        logoutUrl = "/api/google/logout";
      } else {
        logoutUrl = "/api/logout"; // Assuming this is for regular logout
      }

      const response = await axios.get(logoutUrl);
      if (response.status === 200) {
        // Redirect to the home page or any other page after logout
        navigate("/");
      } else {
        console.error("Error:", response.data.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    // header and footer bar and the corresponding page navigations
    <div>
      {children}
      <header className={"row"}>
        <div style={{marginLeft: "10px"}}>
          SnapMatch
        </div>
        <div 
          style={{
            marginRight: '10px',
            display: 'flex',
            alignItems: 'center'}}>         
          <div
                // On click of the leaderboard button, navigates to leaderboard
                onClick={() => navigate("/Leaderboard")}
                style={{ 
                  background: isActive("/Leaderboard") ? "#58af5c" : "#72e478",
                  height: 'calc(8vh)',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '5px',
                  paddingRight: '5px',
                }}
                data-testid="leaderboard-icon"
              >
                <LeaderboardIcon/>
          </div>

          {/* Logout button positioned in the top right corner */}
            <div
              onClick={handleLogout}
              style={{
                height: 'calc(8vh)',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '15px',
                paddingRight: '5px',

              }}

              data-testid="logout-button"
            >
              Logout
            </div>
          </div>
      </header>
      <footer className={"row"} >
        <div className="selection"
          // On click of the other challenges button, navigates to other challenges
          onClick={() => navigate("/other-challenges")}
          style={{
            background: isActive("/other-challenges") ? "#58af5c" : "#72e478",
          }}
        >
          <GroupsIcon data-testid="groups-icon" />
          Respond
        </div>
        <div className="selection"
          // On click of the camera button, navigates to camera
          onClick={() => navigate("/camera")}
          style={{
            background: isActive("/camera") ? "#58af5c" : "#72e478",
          }}
        >
          <CameraAltIcon data-testid="camera-icon" />
          Camera
        </div>
        <div className="selection"
          // On click of the my challenges button, navigates to my challenges

          onClick={() => navigate("/my-challenges")}
          style={{
            background: isActive("/my-challenges") ? "#58af5c" : "#72e478",
          }}
        >
          <AccountCircleIcon data-testid="account-circle-icon"/>
          My challenges
        </div>
      </footer>
    </div>
  );
}

export default Layout;
