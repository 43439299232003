import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Avatar, Card, CardContent, Typography } from '@mui/material';

/**
 * Renders Leaderboard component
 */

const Leaderboard = () => {
    // State for storing the top leaderboard entries
    const [leaderboardItem, setLeaderboardItem] = useState([]);
    // State for storing the user's own leaderboard information
    const [userInfoItem, setUserInfoItem] = useState([]);
     // State for storing the user's placement in the leaderboard
    const [userPlacing, setUserPlacing] = useState(0);
    // Leaderboard data type to store the top 5 users and their corresponding scores
    useEffect(() => {
        const leaderboardValues = async () => {
          try {
            // Send response data to the backend API
            const response = await axios.post("/api/getLeaderboard", {});
            // Set the leaderboard data in state
            setLeaderboardItem(response.data.data);
            // Set the user's info data in state
            setUserInfoItem(response.data.userInfo);
            // Set the user's placement in state
            setUserPlacing(response.data.leaderboardPlace)
            return response.data;
          } catch (error) {
            // Log error if the request fails
            console.error("Error sending challenge to backend:", error);
          }
        };

        // Call the function to fetch data
        leaderboardValues(); // Call the function immediately upon component mounting
      }, []);

    return (
    <div className="leaderboard-container" style={{ marginTop: '70px', marginLeft: '10px', marginTop: 'calc(8vh + 2px)', marginBottom: 'calc(8vh + 2px)', padding: '5px'}}>
        {/* Heading for the leaderboard */}
        <Typography variant="h5" component="h2" style={{ marginBottom: '10px' }}>
            Top 5 SnapMatchers!
        </Typography>
        {/* Render the leaderboard items for the first time */}
        {leaderboardItem.map((item, index) => (
            <Card className="leaderboard-item" key={index} style={{ marginBottom: '10px', height: '10vh' }}>
                <CardContent style={{ display: 'flex', alignItems: 'center' }}>
                    {/* Circle indicating place */}
                    <Avatar style={{ backgroundColor: '#72e478', color: 'black', marginRight: 10 }}>
                        <Typography variant="body1">
                            {index + 1}
                        </Typography>
                    </Avatar>
                     {/* Display the user's name */}
                    <Typography variant="h6" component="h2" style={{ flex: 1 }}>
                        {item.UserName}
                    </Typography>
                     {/* Display the user's score */}
                    <Typography variant="body2" component="p">
                        Score: {item.UserScore}
                    </Typography>
                </CardContent>
            </Card>
        ))}
        {/* Render the user's personal score */}
        <Typography variant="h5" component="h2" style={{ marginBottom: '10px' }}>
            Your Score!
        </Typography>
        {/* Render the leaderboard items for the second time */}
        {userInfoItem.map((item, index) => (
            <Card className="leaderboard-item" key={index + leaderboardItem.length} style={{ marginBottom: '10px', height: '10vh' }}>
                <CardContent style={{ display: 'flex', alignItems: 'center' }}>
                    {/* Circle indicating place */}
                    <Avatar style={{ backgroundColor: '#72e478', color: 'black', marginRight: 10 }}>
                        <Typography variant="body1">
                            {userPlacing}
                        </Typography>
                    </Avatar>
                     {/* Display the user's name */}
                    <Typography variant="h6" component="h2" style={{ flex: 1 }}>
                        {item.UserName}
                    </Typography>
                     {/* Display the user's score */}
                    <Typography variant="body2" component="p">
                        Score: {item.UserScore}
                    </Typography>
                </CardContent>
            </Card>
        ))}
    </div>
);
}

export default Leaderboard;
