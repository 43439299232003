import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import ResponsePage from "../../response/ResponsePage";
import ViewResponses from "../../response/ViewResponses";

/**
 * Component to display details of a challenge, including image, tags, caption, and action buttons.
 * @param {Object} props - Component properties.
 * @param {Object} props.challenge - The challenge object to display details.
 * @param {boolean} props.other - Indicates if the challenge is from "Other Challenges."
 * @param {boolean} props.onlyPreview - Indicates if the component is for preview only.
 * @returns {JSX.Element} Challenge detail component.
 */
export default function MyChallengeDetail({ challenge, other, onlyPreview }) {
  // Hook to navigate to different routes
  // const navigate = useNavigate();
  //   const [response, setResponse] = useState(false);
  const [viewResponse, setViewResponse] = useState(false);

  // If no challenge is provided, return an empty fragment
  if (!challenge) {
    return <></>;
  }

  // Append the timestamp as a query parameter to invalidate the cache
  const imgUrlWithCacheInvalidation = `${
    challenge.ImgPath
  }?cache=${Date.now()}`;

  return (
    <div data-testid="challenge-detail" style={{}}>
      {/* if the user hasn't clicked response button, show the challenge detail */}
      {!viewResponse && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflowY: "auto",
          }}
        >
          <div style={{ maxWidth: "500px" }}>
            {/* Displaying the image */}
            <img
              src={imgUrlWithCacheInvalidation}
              alt=""
              style={{ maxWidth: "100%", maxHeight: "100%" }}
              data-testid="challenge-image"
            />
          </div>

          <div data-testid="challenge-caption" style={{ padding: "10px", textAlign: "center" }}>
            {/* Displaying the Caption */}
            <Typography>{challenge.Caption} </Typography>
            {/* Displaying the Tags */}
            <Typography>Object to match: {challenge.Tags}</Typography>
          </div>
          <div  data-testid="challenge-tag" style={{ display: "flex", marginBottom: "10px" }}>
            {/* Action buttons */}
            {!onlyPreview && (
              <div>
                {/* Show response button (for "Other Challenges" only) */}
                {other ? (
                  <>
                    <Button
                      style={{
                        backgroundColor: "black",
                        color: "#72e478",
                        marginLeft: "10px",
                      }}
                      onClick={() => setViewResponse(true)}
                    >
                      View Responses
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {/* if the user has clicked the view responses button, the responses are rendered */}
      {viewResponse && (
        <ViewResponses
          challengeID={challenge.ChallengeID}
          onBackButtonClick={() => setViewResponse(false)}
        ></ViewResponses>
      )}
    </div>
  );
}
